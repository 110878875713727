// Basic Color
$white: #fff;
$black1: #111;
$black2: #666;
$black3: #b5b5b5;
$black4: #eaeaea;
$black5: #f9fafc;

// Accent color
$blue: #0cb8b6;
$purple: #6758f3;
$green: #37af47;
$red: #e6492d;
$dip-blue: #1665d8;
$light-blue: #27b1d9;
$orange: #f6ab2f;
$gray: #9ea0a5;
$pink: #ff375f;
