/*--------------------------------------------------------------
27. Blog Details
----------------------------------------------------------------*/
.st-page-heading {
  height: 650px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding-top: 120px;
  background-size: cover;
  background-attachment: fixed;

  &.st-size-md {
    height: 450px;
  }

  &:before {
    content: '';
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.6);
  }

  > div {
    position: relative;
    z-index: 1;
  }
}

.st-page-heading-title {
  color: #fff;
  font-size: 48px;
  text-align: center;
  margin-bottom: 7px;
}

.st-breadcamp {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 0;
  margin: 0;

  li {
    color: rgba(255, 255, 255, 0.7);
    list-style: none;

    &:not(:last-child):after {
      content: '/';
      padding-right: 6px;
      margin-left: 6px;
    }
  }

  a {
    &:hover {
      color: #fff;
    }
  }
}

.st-page-heading.st-style1 {
  padding-top: 0;
  height: 320px;
}

.st-widget {
  border: 1px solid $black4;
  border-radius: 7px;
  padding: 30px;

  .st-widget-title {
    font-size: 16px;
    position: relative;
    padding-bottom: 0;
    margin-bottom: 17px;
    margin-top: -3px;
    overflow: hidden;

    span {
      position: relative;
      display: inline-block;
      padding-right: 15px;

      &:before {
        content: '';
        background-color: $black4;
        position: absolute;
        left: 100%;
        bottom: 50%;
        height: 1px;
        width: 1000px;
      }
    }
  }
}

.st-widget-list {
  padding: 0;
  margin: 0;
  list-style: none;
  margin-top: -16px;
  margin-bottom: -16px;
  font-size: 14px;

  li:not(:last-child) {
    border-bottom: 1px solid $black4;
  }

  a {
    display: block;
    padding: 10px 0;

    &:hover {
      color: $blue;
    }
  }

  &.st-color1 {
    a {
      &:hover {
        color: $light-blue;
      }
    }
  }

  &.st-color2 {
    a {
      &:hover {
        color: $pink;
      }
    }
  }
}

.st-tagcloud {
  margin-bottom: -10px;

  .st-tag {
    display: inline-block;
    font-size: 13px;
    padding: 2px 10px;
    border: 1px solid $black4;
    border-radius: 7px;
    margin-right: 6px;
    margin-bottom: 10px;

    &:hover {
      color: #fff;
      background-color: $blue;
      border-color: $blue;
    }
  }

  &.st-color1 {
    .st-tag {
      &:hover {
        background-color: $light-blue;
        border-color: $light-blue;
      }
    }
  }

  &.st-color2 {
    .st-tag {
      &:hover {
        background-color: $pink;
        border-color: $pink;
      }
    }
  }
}

.st-sidebar-social-btn {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: -4px;
  margin-bottom: -5px;

  a:not(:last-child) {
    margin-right: 15px;
  }
}

.st-post-details.st-style1 {
  font-weight: 300;
  font-size: 18px;
  line-height: 1.8em;

  .slick-slide {
    line-height: 0;
  }

  .st-post-thumb {
    margin-bottom: 25px;

    img {
      width: 100%;
    }
  }

  .st-post-title {
    font-size: 28px;
    margin-bottom: 9px;
  }

  blockquote {
    background-color: $black5;
    margin: 0;
    padding: 30px 40px 27px;
    font-size: 24px;
    line-height: 1.6em;
    border-left: 5px solid $blue;
    color: $black1;
    border-radius: 7px;
    font-weight: 400;
    margin: 20px 0;

    small {
      display: block;
      line-height: inherit;
      margin-top: 4px;
      color: $black3;
      font-size: inherit;
      font-size: 16px;

      span {
        color: $blue;
      }
    }
  }

  .st-post-label {
    margin-bottom: 15px;
  }

  .st-post-text p {
    margin-bottom: 15px;
  }

  .st-post-meta {
    display: flex;
    justify-content: space-between;
    padding: 25px 0;
    line-height: 1.6em;
    font-size: 15px;
    border-top: 1px solid $black4;
    border-bottom: 1px solid $black4;
  }

  h2 {
    font-size: 28px;
    margin-bottom: 10px;
  }

  p {
    margin-bottom: 15px;
  }

  img {
    margin-bottom: 20px;
    border-radius: 7px;
    margin-top: 5px;
  }

  .embed-responsive {
    margin-top: 15px;
    margin-bottom: 15px;
  }

  &.st-color1 {
    blockquote {
      border-left-color: $light-blue;

      small {
        span {
          color: $light-blue;
        }
      }
    }
  }
  &.st-color2 {
    blockquote {
      border-left-color: $pink;

      small {
        span {
          color: $pink;
        }
      }
    }
  }
}

.st-post-label {
  > span {
    display: inline-block;
    position: relative;

    &:not(:last-child) {
      padding-right: 15px;
      margin-right: 15px;

      &:before {
        content: '';
        position: absolute;
        height: 16px;
        width: 1px;
        background-color: $black3;
        right: 0;
        top: 3px;
      }
    }
  }

  a:hover {
    color: $blue;
  }
}

.st-post-tage-list {
  display: flex;
  flex-wrap: wrap;
  font-size: 15px;
  font-weight: 400;

  li {
    margin-right: 5px;

    &:not(:last-child):after {
      content: ',';
    }

    a {
      &:hover {
        color: $black1;
      }
    }
  }
}

.st-post-tages,
.st-post-share {
  display: flex;

  .st-post-tage-title,
  .st-post-share-title {
    margin: 0;
    font-size: 16px;
    margin-right: 10px;
    line-height: 1.6em;
  }
}

.st-post-share-btn-list {
  display: flex;
  flex-wrap: wrap;
  font-size: 15px;

  a {
    &:not(:last-child) {
      margin-right: 12px;
    }

    &:hover {
      color: $black1;
    }
  }
}

.st-post-btn-gropu {
  display: flex;
  justify-content: space-between;

  .st-btn {
    min-width: 170px;
    justify-content: center;
  }
}

.st-page-heading-in {
  .st-post-label {
    color: rgba(255, 255, 255, 0.75);
    display: flex;
    justify-content: center;
    margin-top: 6px;
  }

  .st-post-label > span:not(:last-child):before {
    background-color: rgba(255, 255, 255, 0.15);
  }

  .st-post-label > span:not(:last-child) {
    color: rgba(255, 255, 255, 0.75);
  }

  .st-post-label a:hover {
    color: #fff;
  }
}

.st-page-heading-subtitle {
  color: rgba(255, 255, 255, 0.7);
  font-size: 18px;
  font-weight: 300;
  margin-top: 7px;
}

@media screen and (max-width: 991px) {
  .st-page-heading-title {
    font-size: 38px;
  }

  .st-page-heading-subtitle {
    font-size: 16px;
  }

  .st-page-heading.st-size-md {
    height: 350px;
    padding-top: 80px;
  }

  .st-page-heading-title br {
    display: none;
  }

  .st-page-heading {
    height: 500px;
    padding-top: 80px;
  }

  .st-post-details.st-style1 {
    font-size: 16px;
  }

  .st-post-details.st-style1 blockquote {
    padding: 20px 20px 17px;
    font-size: 20px;
  }
}

@media screen and (max-width: 767px) {
  .st-post-details.st-style1 .st-post-meta {
    display: block;
  }

  .st-post-btn-gropu .st-btn {
    min-width: 140px;
    justify-content: center;
    padding: 0.6em 1.5em;
  }
}
