/*--------------------------------------------------------------
3. General
----------------------------------------------------------------*/

@media (min-width: 1400px) {
  .container {
    max-width: 1270px;
  }
}

// @media (min-width: 1400px) {
//   .container {
//     max-width: 1140px;
//   }
// }

.st-shape-wrap > .container {
  position: relative;
  z-index: 10;
}
hr {
  opacity: 1;
}
.st-primary-color {
  color: $black1;
}

.st-mp0 {
  list-style: none;
  margin: 0;
  padding: 0;
}

.st-flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.st-zoom {
  position: relative;
  overflow: hidden;

  .st-zoom-in {
    transition: all 0.4s ease-in-out;
  }

  &:hover {
    .st-zoom-in {
      transform: scale(1.07);
    }
  }
}

.st-vertical-middle {
  display: flex;
  align-items: center;
  height: 100%;

  .st-vertical-middle-in {
    width: 100%;
  }
}

.st-bg {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

.st-content {
  position: relative;
  background-color: $white;
  z-index: 10;
  overflow-x: hidden;
}

.st-sticky-footer {
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  overflow: hidden;
}

.st-gray-bg {
  background-color: $black5;
}

.st-gray-bg1 {
  background-color: #f9f9f9;
}

.st-gray-bg2 {
  background-color: #f8f8fb;
}

.st-bluis-bg {
  background-color: #eaf9fa;
}

.st-gray-overlay {
  position: relative;

  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background-color: rgba($black5, 0.86);
  }

  .container {
    position: relative;
    z-index: 1;
  }
}

hr {
  margin: 0;
  width: 100%;
  border-color: $black4;
}

.st-parallax-shape-wpra {
  position: relative;
}

.st-parallax-shape.st-style1 {
  position: absolute;
  height: 250px;
  width: 250px;
  border-radius: 50%;
  border: 30px solid $black5;
  right: -60px;
  top: 300px;
}

.st-parallax-shape.st-style2 {
  height: 300px;
  width: 300px;
  position: absolute;
  background-color: $black5;
  transform: rotate(-25deg);
  top: 675px;
  left: -9.5%;
}

.st-hover-layer,
.st-hover-layer1,
.st-hover-layer2,
.st-hover-layer3,
.st-hover-layer4,
.st-hover-transform-children {
  position: relative;
  -webkit-transition: -webkit-transform 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: -webkit-transform 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
  -o-transition: all 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
  -webkit-transition: all 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: all 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: all 0.5s cubic-bezier(0.215, 0.61, 0.355, 1),
    -webkit-transform 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
  -webkit-transform: perspective(600px) translate3d(0, 0, 0);
  transform: perspective(600px) translate3d(0, 0, 0);
}

.st-fixed-bg {
  background-attachment: fixed;
}

.st-google-map iframe {
  width: 100%;
  height: 500px;
  display: block;
  border: none;
}

.st-google-map.st-type1 iframe {
  height: 600px;
}

.st-google-map.st-type2 iframe {
  height: 150px;
}

.st-light-blue {
  color: $light-blue;
}

.st-pink {
  color: $pink;
}

.st-blue {
  color: $blue;
}

.st-blue-box {
  color: $blue;
  fill: $blue;
  background-color: rgba($blue, 0.1);
}

.st-purple-box {
  color: $purple;
  fill: $purple;
  background-color: rgba($purple, 0.1);
}

.st-green-box {
  color: $green;
  fill: $green;
  background-color: rgba($green, 0.1);
}

.st-red-box {
  color: $red;
  fill: $red;
  background-color: rgba($red, 0.1);
}

.st-dip-blue-box {
  color: $dip-blue;
  fill: $dip-blue;
  background-color: rgba($dip-blue, 0.1);
}

.st-orange-box {
  color: $orange;
  fill: $orange;
  background-color: rgba($orange, 0.1);
}

.st-gray-box {
  color: $gray;
  fill: $gray;
  background-color: rgba($gray, 0.1);
}

.st-pink-box {
  color: $pink;
  fill: $pink;
  background-color: rgba($pink, 0.1);
}

.st-about-wrap {
  position: relative;

  .st-shape-bg {
    position: absolute;
    bottom: 0;
    width: 100%;
    left: 0;
    z-index: -1;

    img {
      width: 100%;
      height: 500px;
    }

    &:before {
      content: '';
      position: absolute;
      height: 100px;
      width: 100%;
      background-image: linear-gradient(transparent, $white);
      bottom: 0;
      left: 0;
    }
  }
}

.st-tab {
  display: none;
}

.st-tab.active {
  display: block;
}

.st-link-hover-wrap {
  .st-link-hover {
    position: absolute;
    z-index: 2;
    top: -100px;
    right: 20px;
    width: 50px;
    height: 120px;
    background: $blue;
    border-radius: 0 0 40px 40px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    opacity: 0;
    transition: all 0.3s ease;

    i {
      color: $blue;
      background-color: $white;
      height: 40px;
      width: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      margin-bottom: 5px;
      opacity: 0;
      transform: scale(0.5) rotate(180deg);
      transition: all 0.3s ease;
    }
  }

  &:hover {
    .st-link-hover {
      opacity: 1;
      top: 0px;

      i {
        opacity: 1;
        transform: scale(1) rotate(0deg);
        transition-delay: 0.15s;
      }
    }
  }
}

.st-shape-wrap {
  position: relative;

  .st-shape1,
  .st-shape2,
  .st-shape3,
  .st-shape6 {
    position: absolute;
  }

  .st-shape1 {
    left: 0;
    top: 0;
  }

  .st-shape2 {
    bottom: 0;
    right: 0;
  }

  .st-shape6 {
    top: 0;
    right: 0;
  }

  .st-shape3 {
    top: 0;
    right: 0;
  }

  .st-shape4 {
    position: absolute;
    height: 150px;
    width: 100%;
    bottom: 0;
    left: 0;

    img {
      height: 100%;
      width: 100%;
    }
  }

  .st-shape5 {
    position: absolute;
    width: 100%;
    height: calc(100% - 40px);

    img {
      height: 100%;
      width: 100%;
    }
  }
}

.st-about-img {
  min-height: 300px;
  height: calc(100% - 30px);
  width: calc(100% - 30px);
  border-radius: 7px;
  background: rgba($blue, 0.1);
  margin-top: 30px;

  .st-about-img-in {
    height: 100%;
    border-radius: inherit;
    position: relative;
    left: 30px;
    top: -30px;
  }
}

.ui-widget.ui-widget-content {
  z-index: 10 !important;
}

.st-doctors-info-title,
.st-working-hour-title {
  font-size: 20px;
  margin-bottom: 15px;
}

.st-doctors-info,
.st-working-hour {
  border: 1px solid #eaeaea;
  border-radius: 5px;
  padding: 20px 20px;
}

.st-working-hour-list {
  li {
    display: flex;
    justify-content: space-between;
    position: relative;

    b {
      font-weight: 400;
    }

    &:not(:last-child) {
      margin-bottom: 10px;
    }

    .st-offday {
      color: $black3;
    }
  }
}

.st-doctors-info-list {
  li {
    position: relative;
    padding-left: 30px;

    &:not(:last-child) {
      margin-bottom: 10px;
    }

    i {
      position: absolute;
      left: 0;
      top: 5px;
      color: $black3;
    }
  }
}

.st-doctor-name {
  font-size: 24px;
  margin-bottom: 2px;
}

.st-doctor-designation {
  font-size: 16px;
  line-height: 1.6em;
  font-weight: 400;
  color: $black1;
}

.st-doctor-desc {
  margin-top: 10px;
  font-size: 16px;
  line-height: 1.6em;
  color: $black1;
}

.st-doctor-details-box {
  h2 {
    font-size: 20px;
    margin-bottom: 10px;
  }

  p {
    &:last-child {
      margin-bottom: 0;
    }
  }

  ul {
    padding: 0;
    margin: 0;
    list-style: none;

    li {
      &:not(:last-child) {
        margin-bottom: 10px;
      }
    }
  }
}

.st-doctors-special {
  font-size: 16px;
  line-height: 1.6em;
  display: flex;
  flex-wrap: wrap;
  font-weight: 500;

  b {
    display: inline-block;
    font-weight: 600;
    color: $blue;
    margin-right: 15px;
    flex: none;
    min-width: 105px;
  }

  li {
    width: 50%;
    margin-bottom: 10px;
    display: flex;
  }

  &.st-color1 {
    b {
      color: $light-blue;
    }
  }

  &.st-color2 {
    b {
      color: $black1;
    }
  }
}

.st-doctor-details-box {
  font-size: 16px;
  line-height: 1.6em;
}

.st-left-full-width {
  height: 100%;
  width: calc(50vw - 8px);
  margin-left: calc(-50vw + 100% + 23px);
  padding-left: 15px;
  padding-right: 15px;
}

.st-right-full-width {
  height: 100%;
  width: calc(50vw - 9px);
  margin-left: -15px;
  position: relative;
  padding-left: 15px;
  padding-right: 15px;
}

.st-box {
  &.st-size1 {
    max-width: 820px;
  }

  &.st-align-right {
    margin-left: auto;
  }
}

.st-align-right.st-space30 {
  margin-right: 30px;
}

@media screen and (max-width: 1199px) {
  .st-parallax-shape.st-style1,
  .st-parallax-shape.st-style2 {
    display: none;
  }

  .st-left-full-width {
    padding-left: 0;
    padding-right: 0;
    width: 100%;
    margin-left: 0;
    margin-bottom: 40px;
  }

  .st-right-full-width {
    width: 100%;
    margin-left: 0;
    padding: 0;
    margin-bottom: 40px;
  }

  .st-col-reverse-xl {
    flex-direction: column-reverse;
  }
}

@media screen and (max-width: 991px) {
  .st-content {
    margin: 0 !important;
  }

  .st-vertical-middle {
    display: block;
  }

  .st-google-map iframe {
    height: 400px;
  }

  .st-about-wrap .st-shape-bg {
    display: none;
  }

  .st-doctors-info-right {
    padding-left: 0;
  }
}

@media screen and (max-width: 767px) {
  .st-doctors-special li {
    width: 100%;
  }
}
